export const Red = {
  "50": "#FFEBEE",
  "100": "#FFCDD2",
  "200": "#EF9A9A",
  "300": "#E57373",
  "400": "#EF5350",
  "500": "#F44336",
  "600": "#E53935",
  "700": "#D32F2F",
  "800": "#C62828",
  "900": "#B71C1C",
  A100: "#FF8A80",
  A200: "#FF5252",
  A400: "#FF1744",
  A700: "#D50000"
};

export const Cyan = {
  "50": "#E0F7FA",
  "100": "#B2EBF2",
  "200": "#80DEEA",
  "300": "#4DD0E1",
  "400": "#26C6DA",
  "500": "#00BCD4",
  "600": "#00ACC1",
  "700": "#0097A7",
  "800": "#00838F",
  "900": "#006064",
  A100: "#84FFFF",
  A200: "#18FFFF",
  A400: "#00E5FF",
  A700: "#00B8D4"
};

export const Teal = {
  "50": "#E0F2F1",
  "100": "#B2DFDB",
  "200": "#80CBC4",
  "300": "#4DB6AC",
  "400": "#26A69A",
  "500": "#009688",
  "600": "#00897B",
  "700": "#00796B",
  "800": "#00695C",
  "900": "#004D40",
  A100: "#A7FFEB",
  A200: "#64FFDA",
  A400: "#1DE9B6",
  A700: "#00BFA5"
};

export const Grey = {
  "50": "#FAFAFA",
  "100": "#F5F5F5",
  "200": "#EEEEEE",
  "300": "#E0E0E0",
  "400": "#BDBDBD",
  "500": "#9E9E9E",
  "600": "#757575",
  "700": "#616161",
  "800": "#424242",
  "900": "#212121"
};
